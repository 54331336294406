import {
  ID,
  IDRead,
  IDCreate,
  UserRequest,
  UserResponse,
  makeEnum, 
} from '../spec-generators/api-types-helpers.js';
export const Status = makeEnum({
  draft: 'draft',
  private: 'private',
  public: 'public',
  closed: 'closed',
});
type Status = typeof Status[keyof typeof Status];
export const DBLogType = makeEnum({
  INSERT: 'INSERT',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
});
type DBLogType = typeof DBLogType[keyof typeof DBLogType];
export const Role = makeEnum({
  user: 'user',
  manager: 'manager',
  admin: 'admin',
});
type Role = typeof Role[keyof typeof Role];
export type API = {
  ApplicationLog: {
    delete: {};
    create: {
      message: any;
      timestamp: number;
    };
    read: {
      message?: any;
      timestamp?: number;
      id?: number;
    };
    update: {
      message?: any;
      timestamp?: number;
    };
  };

  ApplicationSettings: {
    create: {
      tools?: any | null;
      rights?: any | null;
      maxmbs?: any | null;
    };
    read: {
      tools?: any | null;
      rights?: any | null;
      maxmbs?: any | null;
      id?: number;
    };
    update: {
      tools?: any | null;
      rights?: any | null;
      maxmbs?: any | null;
    };
  };

  Project: {
    delete: {};
    create: {
      title: string;
      subtitle: string;
      description: string;
      status: Status;
      locale: number;
      invitees: string[];
      registrationCode?: string | null;
      subscribers: number[];
      moderators: number[];
      background?: number | null;
      userFeedbacks: number[];
      dialogues: number[];
      files: number[];
      startedAt?: Date | null;
      closedAt?: Date | null;
    };
    read: {
      title?: string;
      subtitle?: string;
      description?: string;
      status?: Status;
      locale?: API['TranslationLanguage']['read'];
      invitees?: string[];
      registrationCode?: string | null;
      subscribersCount?: number;
      subscribers?: API['User']['read'][];
      moderators?: API['User']['read'][];
      background?: API['File']['read'] | null;
      userFeedbacks?: API['UserFeedback']['read'][];
      dialogues?: API['Dialogue']['read'][];
      files?: API['File']['read'][];
      author?: API['User']['read'];
      startedAt?: Date | null;
      closedAt?: Date | null;
      id?: number;
    };
    update: {
      title?: string;
      subtitle?: string;
      description?: string;
      status?: Status;
      locale?: number;
      invitees?: string[];
      registrationCode?: string | null;
      subscribers?: number[];
      moderators?: number[];
      background?: number | null;
      userFeedbacks?: number[];
      dialogues?: number[];
      files?: number[];
      startedAt?: Date | null;
      closedAt?: Date | null;
    };
  };

  TranslationLanguage: {
    delete: {};
    create: {
      languageCode: string;
      projects: number[];
      dialogues: number[];
      messages: number[];
      preferredLocales: number[];
      msgOriginalLanguage: number[];
      msgTargetLanguage: number[];
    };
    read: {
      languageCode?: string;
      projects?: API['Project']['read'][];
      dialogues?: API['Dialogue']['read'][];
      messages?: API['Message']['read'][];
      preferredLocales?: API['PreferredLocale']['read'][];
      msgOriginalLanguage?: API['MessageTranslation']['read'][];
      msgTargetLanguage?: API['MessageTranslation']['read'][];
      id?: number;
    };
    update: {
      languageCode?: string;
      projects?: number[];
      dialogues?: number[];
      messages?: number[];
      preferredLocales?: number[];
      msgOriginalLanguage?: number[];
      msgTargetLanguage?: number[];
    };
  };

  Dialogue: {
    delete: {};
    create: {
      title: string;
      description: string;
      goal: string;
      order: string;
      status: Status;
      registrationCode?: string | null;
      guestAccessCode?: string | null;
      allowGuestAccess?: boolean | null;
      locale: number;
      preferredLocales: number[];
      keywords: number[];
      userFeedbacks: number[];
      project: number;
      subscribers: number[];
      moderators: number[];
      background?: number | null;
      phases: number[];
      startedAt?: Date | null;
      closedAt?: Date | null;
    };
    read: {
      title?: string;
      description?: string;
      goal?: string;
      order?: string;
      status?: Status;
      registrationCode?: string | null;
      guestAccessCode?: string | null;
      allowGuestAccess?: boolean | null;
      locale?: API['TranslationLanguage']['read'];
      preferredLocales?: API['PreferredLocale']['read'][];
      author?: API['User']['read'];
      keywords?: API['Keyword']['read'][];
      userFeedbacks?: API['UserFeedback']['read'][];
      project?: API['Project']['read'];
      subscribers?: API['User']['read'][];
      subscribersCount?: number;
      moderators?: API['User']['read'][];
      background?: API['File']['read'] | null;
      phases?: API['Phase']['read'][];
      startedAt?: Date | null;
      closedAt?: Date | null;
      id?: number;
    };
    update: {
      title?: string;
      description?: string;
      goal?: string;
      order?: string;
      status?: Status;
      registrationCode?: string | null;
      guestAccessCode?: string | null;
      allowGuestAccess?: boolean | null;
      locale?: number;
      preferredLocales?: number[];
      keywords?: number[];
      userFeedbacks?: number[];
      project?: number;
      subscribers?: number[];
      moderators?: number[];
      background?: number | null;
      phases?: number[];
      startedAt?: Date | null;
      closedAt?: Date | null;
    };
  };

  PreferredLocale: {
    delete: {};
    create: {
      user: number;
      dialogue: number;
      preferredLanguage: number;
    };
    read: {
      user?: API['User']['read'];
      dialogue?: API['Dialogue']['read'];
      preferredLanguage?: API['TranslationLanguage']['read'];
      id?: number;
    };
    update: {
      user?: number;
      dialogue?: number;
      preferredLanguage?: number;
    };
  };

  User: {
    delete: {};
    create: {
      subscribedToProjects: number[];
      moderatesProjects: number[];
      subscribedToDialogues: number[];
      moderatesDialogues: number[];
      username: string;
      email?: string | null;
      roles: Role[];
      locale?: string | null;
      password?: string | null;
      about: string;
      avatar?: number | null;
      userFeedbacks: number[];
      votes: number[];
      messages: number[];
      likes: number[];
      uploadedFiles: number[];
      dialogues: number[];
      projects: number[];
      registrationCode: string;
      guestAccessCode?: string | null;
      isGuest?: boolean | null;
      ui?: number | null;
      preferredLocales: number[];
    };
    read: {
      subscribedToProjects?: API['Project']['read'][];
      moderatesProjects?: API['Project']['read'][];
      subscribedToDialogues?: API['Dialogue']['read'][];
      moderatesDialogues?: API['Dialogue']['read'][];
      username?: string;
      email?: string | null;
      roles?: Role[];
      locale?: string | null;
      isVerified?: boolean;
      about?: string;
      avatar?: API['File']['read'] | null;
      userFeedbacks?: API['UserFeedback']['read'][];
      votes?: API['Vote']['read'][];
      messages?: API['Message']['read'][];
      likes?: API['Like']['read'][];
      uploadedFiles?: API['File']['read'][];
      dialogues?: API['Dialogue']['read'][];
      projects?: API['Project']['read'][];
      logs?: API['Log']['read'][];
      ui?: API['UiState']['read'] | null;
      preferredLocales?: API['PreferredLocale']['read'][];
      id?: number;
    };
    update: {
      subscribedToProjects?: number[];
      moderatesProjects?: number[];
      subscribedToDialogues?: number[];
      moderatesDialogues?: number[];
      username?: string;
      email?: string | null;
      roles?: Role[];
      locale?: string | null;
      isVerified?: boolean;
      password?: string | null;
      about?: string;
      avatar?: number | null;
      userFeedbacks?: number[];
      votes?: number[];
      messages?: number[];
      likes?: number[];
      uploadedFiles?: number[];
      dialogues?: number[];
      projects?: number[];
      registrationCode?: string;
      guestAccessCode?: string | null;
      isGuest?: boolean | null;
      ui?: number | null;
      preferredLocales?: number[];
    };
  };

  File: {
    delete: {};
    create: {
      description: string;
      file_path: string;
      fileName: string;
      uri: string;
      order: string;
      folder: string;
      size: number;
      mimeType: string;
      uploadTime?: Date | null;
      projects: number[];
      dialogues: number[];
      avatarsFor: number[];
      messages: number[];
      libraries: number[];
      projectFiles: number[];
      documentFiles: number[];
    };
    read: {
      description?: string;
      file_path?: string;
      fileName?: string;
      author?: API['User']['read'];
      uri?: string;
      order?: string;
      size?: number;
      mimeType?: string;
      uploadTime?: Date | null;
      projects?: API['Project']['read'][];
      dialogues?: API['Dialogue']['read'][];
      avatarsFor?: API['User']['read'][];
      messages?: API['Message']['read'][];
      libraries?: API['LibraryBlock']['read'][];
      projectFiles?: API['Project']['read'][];
      documentFiles?: API['Document']['read'][];
      id?: number;
    };
    update: {
      description?: string;
      file_path?: string;
      fileName?: string;
      uri?: string;
      order?: string;
      folder?: string;
      size?: number;
      mimeType?: string;
      uploadTime?: Date | null;
      projects?: number[];
      dialogues?: number[];
      avatarsFor?: number[];
      messages?: number[];
      libraries?: number[];
      projectFiles?: number[];
      documentFiles?: number[];
    };
  };

  Message: {
    delete: {};
    create: {
      chatBlockContainer?: number | null;
      libraryBlockContainer?: number | null;
      content: string;
      edited: boolean;
      time: Date;
      color: string;
      checked: boolean;
      locale: number;
      author: number;
      likes: number[];
      replyTo?: number | null;
      replies: number[];
      listItem?: number | null;
      option?: number | null;
      files: number[];
      translations: number[];
    };
    read: {
      chatBlockContainer?: API['ChatBlock']['read'] | null;
      libraryBlockContainer?: API['LibraryBlock']['read'] | null;
      content?: string;
      edited?: boolean;
      time?: Date;
      color?: string;
      checked?: boolean;
      locale?: API['TranslationLanguage']['read'];
      author?: API['User']['read'];
      likes?: API['Like']['read'][];
      replyTo?: API['Message']['read'] | null;
      replies?: API['Message']['read'][];
      listItem?: API['ListItem']['read'] | null;
      option?: API['Option']['read'] | null;
      files?: API['File']['read'][];
      translations?: API['MessageTranslation']['read'][];
      id?: number;
    };
    update: {
      chatBlockContainer?: number | null;
      libraryBlockContainer?: number | null;
      content?: string;
      edited?: boolean;
      time?: Date;
      color?: string;
      checked?: boolean;
      locale?: number;
      author?: number;
      likes?: number[];
      replyTo?: number | null;
      replies?: number[];
      listItem?: number | null;
      option?: number | null;
      files?: number[];
      translations?: number[];
    };
  };

  ChatBlock: {
    delete: {};
    create: {
      parent: number;
      messages: number[];
    };
    read: {
      parent?: API['Block']['read'];
      messages?: API['Message']['read'][];
      id?: number;
    };
    update: {
      parent?: number;
      messages?: number[];
    };
  };

  Block: {
    delete: {};
    create: {
      order: string;
      name: string;
      description: string;
      phase: number;
      childType: string;
      childDocumentBlock?: number | null;
      childPollBlock?: number | null;
      childChatBlock?: number | null;
      childListBlock?: number | null;
      childLibraryBlock?: number | null;
      childSudokuBlock?: number | null;
      locked?: boolean | null;
    };
    read: {
      order?: string;
      name?: string;
      description?: string;
      phase?: API['Phase']['read'];
      childType?: string;
      childDocumentBlock?: API['DocumentBlock']['read'] | null;
      childPollBlock?: API['PollBlock']['read'] | null;
      childChatBlock?: API['ChatBlock']['read'] | null;
      childListBlock?: API['ListBlock']['read'] | null;
      childLibraryBlock?: API['LibraryBlock']['read'] | null;
      childSudokuBlock?: API['SudokuBlock']['read'] | null;
      locked?: boolean | null;
      id?: number;
    };
    update: {
      order?: string;
      name?: string;
      description?: string;
      phase?: number;
      childType?: string;
      childDocumentBlock?: number | null;
      childPollBlock?: number | null;
      childChatBlock?: number | null;
      childListBlock?: number | null;
      childLibraryBlock?: number | null;
      childSudokuBlock?: number | null;
      locked?: boolean | null;
    };
  };

  Phase: {
    delete: {};
    create: {
      order: string;
      name: string;
      description: string;
      dateBegin?: Date | null;
      dateEnd?: Date | null;
      dialogue: number;
      blocks: number[];
      active: boolean;
      fixed?: boolean | null;
    };
    read: {
      order?: string;
      name?: string;
      description?: string;
      dateBegin?: Date | null;
      dateEnd?: Date | null;
      dialogue?: API['Dialogue']['read'];
      blocks?: API['Block']['read'][];
      active?: boolean;
      fixed?: boolean | null;
      id?: number;
    };
    update: {
      order?: string;
      name?: string;
      description?: string;
      dateBegin?: Date | null;
      dateEnd?: Date | null;
      dialogue?: number;
      blocks?: number[];
      active?: boolean;
      fixed?: boolean | null;
    };
  };

  DocumentBlock: {
    delete: {};
    create: {
      parent: number;
      document?: number | null;
      readOnly: boolean;
    };
    read: {
      parent?: API['Block']['read'];
      document?: API['Document']['read'] | null;
      readOnly?: boolean;
      id?: number;
    };
    update: {
      parent?: number;
      document?: number | null;
      readOnly?: boolean;
    };
  };

  Document: {
    delete: {};
    create: {
      block?: number | null;
      text: any;
      files: number[];
    };
    read: {
      block?: API['DocumentBlock']['read'] | null;
      text?: any;
      files?: API['File']['read'][];
      id?: number;
    };
    update: {
      block?: number | null;
      text?: any;
      files?: number[];
    };
  };

  PollBlock: {
    delete: {};
    create: {
      parent: number;
      poll?: number | null;
    };
    read: {
      parent?: API['Block']['read'];
      poll?: API['Poll']['read'] | null;
      id?: number;
    };
    update: {
      parent?: number;
      poll?: number | null;
    };
  };

  Poll: {
    delete: {};
    create: {
      parent: number;
      start?: Date | null;
      end?: Date | null;
      closed: boolean;
      options: number[];
      showResults: boolean;
      allowCancellation: boolean;
    };
    read: {
      parent?: API['PollBlock']['read'];
      start?: Date | null;
      end?: Date | null;
      closed?: boolean;
      options?: API['Option']['read'][];
      showResults?: boolean;
      allowCancellation?: boolean;
      id?: number;
    };
    update: {
      parent?: number;
      start?: Date | null;
      end?: Date | null;
      closed?: boolean;
      options?: number[];
      showResults?: boolean;
      allowCancellation?: boolean;
    };
  };

  Option: {
    delete: {};
    create: {
      poll: number;
      votes: number[];
      message: number;
      order: string;
    };
    read: {
      poll?: API['Poll']['read'];
      votes?: API['Vote']['read'][];
      message?: API['Message']['read'];
      order?: string;
      id?: number;
    };
    update: {
      poll?: number;
      votes?: number[];
      message?: number;
      order?: string;
    };
  };

  Vote: {
    delete: {};
    create: {
      voted: Date;
      option: number;
      voter: number;
    };
    read: {
      voted?: Date;
      option?: API['Option']['read'];
      voter?: API['User']['read'];
      id?: number;
    };
    update: {
      voted?: Date;
      option?: number;
      voter?: number;
    };
  };

  ListBlock: {
    delete: {};
    create: {
      parent: number;
      layout_x: number;
      layout_y: number;
      zoomlevel: number;
      canvas: boolean;
      editable: boolean;
      likes: boolean;
      snap: boolean;
      grid: boolean;
      checkboxes: boolean;
      colors: boolean;
      attribution: boolean;
      listType: string;
      lists: number[];
    };
    read: {
      parent?: API['Block']['read'];
      layout_x?: number;
      layout_y?: number;
      zoomlevel?: number;
      canvas?: boolean;
      editable?: boolean;
      likes?: boolean;
      snap?: boolean;
      grid?: boolean;
      checkboxes?: boolean;
      colors?: boolean;
      attribution?: boolean;
      listType?: string;
      lists?: API['List']['read'][];
      id?: number;
    };
    update: {
      parent?: number;
      layout_x?: number;
      layout_y?: number;
      zoomlevel?: number;
      canvas?: boolean;
      editable?: boolean;
      likes?: boolean;
      snap?: boolean;
      grid?: boolean;
      checkboxes?: boolean;
      colors?: boolean;
      attribution?: boolean;
      listType?: string;
      lists?: number[];
    };
  };

  List: {
    delete: {};
    create: {
      parent: number;
      name: string;
      order: string;
      coordinate_x: number;
      coordinate_y: number;
      listItems: number[];
    };
    read: {
      parent?: API['ListBlock']['read'];
      name?: string;
      order?: string;
      coordinate_x?: number;
      coordinate_y?: number;
      listItems?: API['ListItem']['read'][];
      id?: number;
    };
    update: {
      parent?: number;
      name?: string;
      order?: string;
      coordinate_x?: number;
      coordinate_y?: number;
      listItems?: number[];
    };
  };

  ListItem: {
    delete: {};
    create: {
      list: number;
      coordinate_x: number;
      coordinate_y: number;
      order: string;
      alt?: string | null;
      pinned?: boolean | null;
      message: number;
    };
    read: {
      list?: API['List']['read'];
      coordinate_x?: number;
      coordinate_y?: number;
      order?: string;
      alt?: string | null;
      pinned?: boolean | null;
      message?: API['Message']['read'];
      id?: number;
    };
    update: {
      list?: number;
      coordinate_x?: number;
      coordinate_y?: number;
      order?: string;
      alt?: string | null;
      pinned?: boolean | null;
      message?: number;
    };
  };

  LibraryBlock: {
    delete: {};
    create: {
      parent: number;
      files: number[];
      links: number[];
    };
    read: {
      parent?: API['Block']['read'];
      files?: API['File']['read'][];
      links?: API['Message']['read'][];
      id?: number;
    };
    update: {
      parent?: number;
      files?: number[];
      links?: number[];
    };
  };

  SudokuBlock: {
    delete: {};
    create: {
      parent: number;
      solution: string;
      challenge: string;
      attempt: string;
      hints?: number | null;
    };
    read: {
      parent?: API['Block']['read'];
      solution?: string;
      challenge?: string;
      attempt?: string;
      hints?: number | null;
      id?: number;
    };
    update: {
      parent?: number;
      solution?: string;
      challenge?: string;
      attempt?: string;
      hints?: number | null;
    };
  };

  Like: {
    delete: {};
    create: {
      liker: number;
      liked: number;
    };
    read: {
      liker?: API['User']['read'];
      liked?: API['Message']['read'];
      id?: number;
    };
    update: {
      liker?: number;
      liked?: number;
    };
  };

  MessageTranslation: {
    delete: {};
    create: {
      message: number;
      originalLanguage?: number | null;
      targetLanguage: number;
      translation: string;
    };
    read: {
      message?: API['Message']['read'];
      originalLanguage?: API['TranslationLanguage']['read'] | null;
      targetLanguage?: API['TranslationLanguage']['read'];
      translation?: string;
      id?: number;
    };
    update: {
      message?: number;
      originalLanguage?: number | null;
      targetLanguage?: number;
      translation?: string;
    };
  };

  UserFeedback: {
    delete: {};
    create: {
      activity: string;
      feedback: string;
      project: number;
      user: number;
      dialogue: number;
    };
    read: {
      activity?: string;
      feedback?: string;
      project?: API['Project']['read'];
      user?: API['User']['read'];
      dialogue?: API['Dialogue']['read'];
      id?: number;
    };
    update: {
      activity?: string;
      feedback?: string;
      project?: number;
      user?: number;
      dialogue?: number;
    };
  };

  Log: {
    create: {
      message: string;
      timestamp: Date;
    };
    read: {
      message?: string;
      timestamp?: Date;
      user?: API['User']['read'];
      id?: number;
    };
    update: {
      message?: string;
      timestamp?: Date;
    };
  };

  UiState: {
    create: {
      state: any;
      user: number[];
    };
    read: {
      state?: any;
      user?: API['User']['read'][];
      id?: number;
    };
    update: {
      state?: any;
      user?: number[];
    };
  };

  Keyword: {
    delete: {};
    create: {
      text: string;
      dialogue?: number | null;
    };
    read: {
      text?: string;
      dialogue?: API['Dialogue']['read'] | null;
      id?: number;
    };
    update: {
      text?: string;
      dialogue?: number | null;
    };
  };

  Session: {
    create: {
      Email: string;
      Password: string;
    };
  };

  Subscription: {
    update: {
      ProjectId?: number;
      DialogueId?: number;
      Subscribe?: boolean;
      RegistrationCode?: string;
    };
  };

  Account: {
    update: {
      Email?: string;
      Password?: string;
      Token?: string;
      RegistrationCode?: string;
    };
  };

  DBLog: {
    delete: {};
    create: {
      table_name: string;
      old_row_data?: any | null;
      new_row_data?: any | null;
      dml_type: DBLogType;
      dml_timestamp: Date;
    };
    read: {
      table_name?: string;
      old_row_data?: any | null;
      new_row_data?: any | null;
      dml_type?: DBLogType;
      dml_timestamp?: Date;
      id?: number;
    };
    update: {
      table_name?: string;
      old_row_data?: any | null;
      new_row_data?: any | null;
      dml_type?: DBLogType;
      dml_timestamp?: Date;
    };
  };

  MeetingLog: {
    delete: {};
    create: {
      start: Date;
      end: Date;
      folderName: string;
      recordingName: string;
    };
    read: {
      start?: Date;
      end?: Date;
      folderName?: string;
      recordingName?: string;
      id?: number;
    };
    update: {
      start?: Date;
      end?: Date;
      folderName?: string;
      recordingName?: string;
    };
  };
};
export const ModelName = ['ApplicationLog', 'ApplicationSettings', 'Project', 'TranslationLanguage', 'Dialogue', 'PreferredLocale', 'User', 'File', 'Message', 'ChatBlock', 'Block', 'Phase', 'DocumentBlock', 'Document', 'PollBlock', 'Poll', 'Option', 'Vote', 'ListBlock', 'List', 'ListItem', 'LibraryBlock', 'SudokuBlock', 'Like', 'MessageTranslation', 'UserFeedback', 'Log', 'UiState', 'Keyword', 'Session', 'Subscription', 'Account', 'DBLog', 'MeetingLog'] as const; 
 export type ModelName = typeof ModelName[number];